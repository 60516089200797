// Overriding default ckeditor variables, for a full list go to: https://ckeditor.com/docs/ckeditor5/latest/framework/deep-dive/ui/theme-customization.html#customization-with-css-variables

@use "sass:map";
@use "@angular/material" as mat;

:root servefast-root {
  --ck-color-base-background: var(--mat-sys-surface);
  --ck-color-base-foreground: var(--mat-sys-on-surface);
  --ck-color-base-border: #ccced1;
  --ck-color-base-action: #53a336;
  --ck-color-base-focus: var(--mat-sys-primary);
  --ck-color-base-text: var(--ck-color-base-foreground);
  --ck-color-base-active: var(--mat-sys-primary);
  --ck-color-base-active-focus: var(--mat-sys-primary);
  --ck-color-base-error: #db3700;
  --ck-color-focus-border-coordinates: 218, 81.8%, 56.9%;
  --ck-color-focus-border: rgb(from var(--mat-sys-primary) r g b / 0.5);
  --ck-color-focus-outer-shadow: #cae1fc;
  --ck-color-focus-disabled-shadow: rgba(119, 186, 248, 0.3);
  --ck-color-focus-error-shadow: rgba(255, 64, 31, 0.3);
  --ck-color-text: var(--ck-color-base-text);
  --ck-color-shadow-drop: rgba(0, 0, 0, 0.15);
  --ck-color-shadow-drop-active: rgba(0, 0, 0, 0.2);
  --ck-color-shadow-inner: rgba(0, 0, 0, 0.1);
  --ck-color-button-default-background: transparent;
  --ck-color-button-default-hover-background: rgb(from var(--mat-sys-primary) r g b / 0.3);
  --ck-color-button-default-active-background: rgb(from var(--mat-sys-primary) r g b / 0.2);
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-on-background: rgb(from var(--mat-sys-primary) r g b / 0.2);
  --ck-color-button-on-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-on-active-background: var(--ck-color-button-default-active-background);
  --ck-color-button-on-disabled-background: transparent;
  --ck-color-button-on-color: var(--ck-color-base-foreground);
  --ck-color-button-action-background: var(--ck-color-base-action);
  --ck-color-button-action-hover-background: #4d9d30;
  --ck-color-button-action-active-background: #4d9d30;
  --ck-color-button-action-disabled-background: #7ec365;
  --ck-color-button-action-text: var(--ck-color-base-background);
  --ck-color-button-save: #008a00;
  --ck-color-button-cancel: #db3700;
  --ck-color-switch-button-off-background: #939393;
  --ck-color-switch-button-off-hover-background: #7d7d7d;
  --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
  --ck-color-switch-button-on-hover-background: #4d9d30;
  --ck-color-switch-button-inner-background: var(--ck-color-base-background);
  --ck-color-switch-button-inner-shadow: rgba(0, 0, 0, 0.1);
  --ck-color-dropdown-panel-background: var(--ck-color-base-background);
  --ck-color-dropdown-panel-border: var(--ck-color-base-border);
  --ck-color-dialog-background: var(--ck-custom-background);
  --ck-color-dialog-form-header-border: var(--ck-custom-border);
  --ck-color-input-background: var(--ck-color-base-background);
  --ck-color-input-border: var(--ck-color-base-border);
  --ck-color-input-error-border: var(--ck-color-base-error);
  --ck-color-input-text: var(--ck-color-base-text);
  --ck-color-input-disabled-background: #f2f2f2;
  --ck-color-input-disabled-border: var(--ck-color-base-border);
  --ck-color-input-disabled-text: #757575;
  --ck-color-list-background: var(--ck-color-base-background);
  --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-list-button-on-background: var(--ck-color-button-on-color);
  --ck-color-list-button-on-background-focus: var(--ck-color-button-on-color);
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  --ck-color-panel-background: var(--ck-color-base-background);
  --ck-color-panel-border: var(--ck-color-base-border);
  --ck-color-toolbar-background: var(--ck-color-base-background);
  --ck-color-toolbar-border: var(--ck-color-base-border);
  --ck-color-tooltip-background: var(--ck-color-base-text);
  --ck-color-tooltip-text: var(--ck-color-base-background);
  --ck-color-engine-placeholder-text: #707070;
  --ck-color-upload-bar-background: #6cb5f9;
  --ck-color-link-default: #0000f0;
  --ck-color-link-selected-background: rgba(31, 176, 255, 0.1);
  --ck-color-link-fake-selection: rgba(31, 176, 255, 0.3);
  --ck-color-highlight-background: #ff0;
  --ck-color-light-red: #fcc;
  --ck-disabled-opacity: 0.5;
  --ck-focus-outer-shadow-geometry: 0 0 0 3px;
  --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-outer-shadow);
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry)
    var(--ck-color-focus-disabled-shadow);
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry)
    var(--ck-color-focus-error-shadow);
  --ck-focus-ring: 1px solid var(--ck-color-focus-border);
  --ck-font-size-base: 13px;
  --ck-line-height-base: 1.84615;
  --ck-font-face: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  --ck-font-size-tiny: 0.7em;
  --ck-font-size-small: 0.75em;
  --ck-font-size-normal: 1em;
  --ck-font-size-big: 1.4em;
  --ck-font-size-large: 1.8em;
  --ck-ui-component-min-height: 2.3em;

  // --ck-color-toolbar-background: var(--mat-sys-background, app-bar)};
  // --ck-color-base-text: var(--ck-color-base-foreground);
  // --ck-color-text: var(--ck-color-base-text);
  // --ck-color-base-active: var(--mat-sys-primary, default)};
  // --ck-color-base-active-focus: var(--mat-sys-primary, default)};
}
