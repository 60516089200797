@keyframes skeletion-animation-keyframes {
  50% {
    opacity: 1;
  }
}

@mixin skeletonAnimation() {
  opacity: 0.6;
  animation: skeletion-animation-keyframes 2s infinite !important;
}

$backgroundColor: var(--skeleton-background-color);
$cardBorderRadius: 0.5rem;

[skeleton-button] {
  display: block;
  background-color: $backgroundColor;
  height: 36px;
  width: 6rem;
  border-radius: 0.25rem;
  @include skeletonAnimation();
}

[skeleton-title] {
  display: flex;
  background-color: $backgroundColor;
  height: 1.4rem;
  border-radius: 0.25rem;
  @include skeletonAnimation();
}

[skeleton-text] {
  display: flex;
  background-color: $backgroundColor;
  height: 1rem;
  border-radius: 0.25rem;
  @include skeletonAnimation();
}

[skeleton-image] {
  display: flex;
  border-radius: 0.5rem;
  background-color: $backgroundColor;
  width: 4rem;
  height: 4rem;
  @include skeletonAnimation();
}

[skeleton-card] {
  display: flex;
  border: solid 0.15rem $backgroundColor;
  border-radius: $cardBorderRadius;
  padding: 1rem;
  @include skeletonAnimation();
}

[skeleton-chip] {
  display: flex;
  background-color: $backgroundColor;
  border-radius: 20rem;
  height: 1.5rem;
  width: 8rem;
  @include skeletonAnimation();
}
